/* Basic Reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
::-webkit-scrollbar-thumb {
	width: 8px;
	background-color: #c8c8c8;
}

::-webkit-scrollbar {
	width: 8px;
	height: 8px;
	background-color: #c8c8c83f;
}

  /* Full viewport height and background color */
  html, body {
    height: 100%;
    font-family: Arial, sans-serif;
    background-color: #ffffffc3;
  }
  
  /* Main App container */
  .App {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-direction: column;
    text-align: center;
  }
  
  /* Button styles */
 button.csv-to-json-btn  {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 15px 30px;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    outline: none;
  }
  
  button.csv-to-json-btn:hover {
    background-color: #0056b3;
  }
  
  button.csv-to-json-btn:active {
    transform: scale(0.98);
  }
  
  /* Responsive typography */
  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    color: #333;
  }
  
  /* Additional styles for larger screens */
  @media (min-width: 768px) {
    .App {
      flex-direction: row;
      justify-content: space-around;
    }
  
    button.csv-to-json-btn {
      padding: 10px 20px;
      font-size: 1.2rem;
    }
  }
  
  /* Additional styles for smaller screens */
  @media (max-width: 576px) {
    .App {
      flex-direction: column;
      padding: 10px;
    }
  
    button.csv-to-json-btn {
      width: 100%;
      padding: 15px;
      font-size: 1rem;
    }
  }
  