

/* Container */
.one-d-container {
    width: 90%;
    max-width: 1200px;
    margin: 20px auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    border: 2px solid #00bcd4;
}

/* Heading */
h1 {
    font-size: 2.5rem;
    color: #00796b;
    margin-bottom: 20px;
    text-align: center;
}

/* Input section */
.input-section {
    margin-bottom: 20px;
}

.file-input {
    font-size: 1rem;
    padding: 12px;
    border: 2px solid #00bcd4;
    border-radius: 8px;
    width: 100%;
    background-color: #e0f7fa;
    color: #00796b;
    transition: background-color 0.3s ease, border-color 0.3s ease;
}

.file-input:hover {
    background-color: #b2ebf2;
    border-color: #00796b;
}

/* Select section */
.select-section {
    margin-bottom: 20px;
    position: relative; /* For custom arrow positioning */
}

.key-position-select {
    font-size: 1rem;
    padding: 12px;
    border: 2px solid #00bcd4;
    border-radius: 8px;
    width: 100%;
    background-color: #e0f7fa;
    color: #00796b;
    transition: background-color 0.3s ease, border-color 0.3s ease;
    position: relative;
    appearance: none; /* Remove default styling */
}

/* Add a custom arrow to the select element */
.key-position-select::after {
    content: "▼";
    color: #00796b;
    font-size: 1.2rem;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}

/* Add padding to select element to accommodate the dropdown arrow */
.key-position-select {
    padding-right: 30px; /* Adjust based on the size of the arrow */
}

/* Custom option styles - note this might not be fully supported */
.key-position-select option {
    background-color: #ffffff;
    color: #00796b;
}

.key-position-select option:hover {
    background-color: #b2ebf2; /* Hover effect for the options */
}

/* Convert button */
.convert-button {
    display: inline-block;
    padding: 12px 24px;
    font-size: 1.1rem;
    color: #ffffff;
    background-color: #00796b;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.convert-button:hover {
    background-color: #004d40;
    transform: scale(1.05);
}

/* Table rendering */
.render-table {
    margin-bottom: 20px;
}

.table-summary {
    font-size: 1rem;
    color: #00796b;
    margin-bottom: 10px;
    text-align: center;
}

/* Output section */
.output-section {
    margin-top: 20px;
    padding: 10px;
    border-radius: 8px;
    background-color: #e0f7fa;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.output-section h2 {
    font-size: 1.8rem;
    color: #00796b;
    margin-bottom: 10px;
    text-align: center;
}

/* Responsive design */
@media (max-width: 768px) {
    .one-d-container {
        width: 95%;
        padding: 15px;
    }

    .file-input, .key-position-select {
        font-size: 0.9rem;
    }

    .convert-button {
        font-size: 1rem;
        padding: 10px 20px;
    }
}

/* Responsive adjustments for very small screens */
@media (max-width: 576px) {
    .file-input, .key-position-select {
        font-size: 0.8rem;
    }

    .convert-button {
        font-size: 0.9rem;
        padding: 8px 16px;
    }

    .table-summary {
        font-size: 0.9rem;
    }
}
#download-btn {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 15px;
    padding: 10px 20px;
    margin-bottom: 20px;
    margin-left: auto;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

#download-btn:hover {
    background-color: #0056b3;
}

#download-btn:active {
    background-color: #004494;
}

#download-btn:focus {
    outline: none;
}