/* General table styles */
.csv-table {
    width: 100%;
    border-collapse: collapse;
}

/* Table headers and cells */
.csv-table th, .csv-table td {
    border: 1px solid #ddd;
    padding: 8px;
}

/* Table header styles */
.csv-table th {
    background-color: #f2f2f2;
    text-align: left;
}

/* Container for table with scroll bars */
.csv-table-container {
    height: 300px; /* Adjust as needed */
    overflow: auto; /* Allows both vertical and horizontal scrolling */
    border: 1px solid #ddd; /* Optional: to visualize the container boundaries */
    margin-top: 20px; /* Optional: to give space between elements */
}

/* Table cells with word breaking */
.csv-table tr td {
    max-width: 200px;
    overflow-wrap: break-word;
}

/* Button styling */
.convert-button {
    margin-top: 10px;
    padding: 10px 15px;
    background-color: #007bff; /* Primary color for the button */
    color: white; /* Text color */
    border: none;
    border-radius: 4px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    font-size: 16px; /* Adjust as needed */
    transition: background-color 0.3s; /* Smooth transition for hover effect */
}

/* Button hover effect */
.convert-button:hover {
    background-color: #0056b3; /* Darker shade for hover effect */
}

/* Container padding */
.one-d-container {
    padding: 20px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .csv-table th, .csv-table td {
        padding: 6px; /* Adjust padding for smaller screens */
    }

    .convert-button {
        font-size: 14px; /* Adjust button font size */
        padding: 8px 12px; /* Adjust button padding */
    }
}
